import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';



export const ContactForm = () =>{

        const [value, setValue] = useState('false');
      
        const handleChange = (event) => {
          //setValue(event.target.value);
        };
        const onChange = (event) => {
          console.log(event);
          setValue('true');
          return event;

          };
        const handleClick = (event) => {
            console.log(event);
          };


        return (
          <Box
            component="form"
            className="contactForm"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '300px' },
            }}
            noValidate
            autoComplete="off"
          >

            <div>
              <TextField
                id="filled-multiline-flexible"
                label="Name"
                multiline
                maxRows={4}
                placeholder="Andrew James"
                onChange={handleChange}
                variant="filled"
              />
              <TextField
                id="filled-textarea"
                label="Email"
                placeholder="email@email.com"
                multiline
                variant="filled"
              />
              <TextField
                id="filled-multiline-static"
                label="Message"
                multiline
                rows={4}
                defaultValue="Say hi!"
                variant="filled"
              />

               <Button 
               variant="contained"
               disabled={true}
                onClick={handleClick}>Send</Button>
              {/* <GoogleReCaptchaProvider reCaptchaKey="6Le_TaMdAAAAAFgcGYqTEQQ50oLE7tqxHpJ-1OEP">
                <GoogleReCaptcha onVerify={onChange} />
              </GoogleReCaptchaProvider> */}
            </div>
          </Box>
        );
      }