
import React, { useRef} from 'react';
import * as THREE from 'three';
import { useFrame} from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei'


export function Camera(props) {
  //_, |, \
  const locations = [[200, 20, 0], [300, 30, 0], [400, 40, 0],[400, 100, 0],[400, 100, 0]];

    const ref = useRef();
    const location = props.cameraLocationStep;
    const cl = locations[location];
    const CameraTarget = new THREE.Vector3(cl[0], cl[1], cl[2]);
   
    useFrame((state, delta) => {

        const interp = (location >= 0) ? Math.PI * 0.01 : 1.0;
        return ( 
        ref.current.name ="mainCamera",
        ref.current.position.lerp(CameraTarget, interp),
        ref.current.lookAt(new THREE.Vector3(0, 0, 0))

        )

    });
    
    return (
      <PerspectiveCamera
      makeDefault
        {...props}
        ref={ref}
        />
    )
  }