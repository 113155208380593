import React, {Suspense} from 'react';
import * as THREE from 'three';
import { Canvas} from '@react-three/fiber';
import { MapControls } from '@react-three/drei';
import {Camera} from './Camera';
import {PointLight} from './Lights';
import {Box} from './Box';
import {Plane} from './Plane';
import {Points} from './Points';
import {StarFerry} from './StarFerry';

export class CanvasElement extends React.Component {
    constructor(props){
        super(props);
        this.step = props.step;
    }
    render(){
        const step = this.props.step;

        return (
            <div className='stickyContainer' >
            <Suspense fallback={<div>Loading...</div>}>
            
            <Canvas onCreated={({gl})=>{
                // gl.shadowMap.enabled = false;
                // gl.shadowMap.type = THREE.PCFSoftShadowMap;
                }}
                >
                <Camera cameraLocationStep={(step)? step: 0} />

                <fog attach='fog' args={['#FFFFFF', 5, 400]} />
                <ambientLight color={0xAAAAAA} intensity={0.6} />
                <PointLight />
                <MapControls maxDistance={150}  minDistance={150} />
                <StarFerry position={[-500, 0, 0]} step={(step)? step: 0} castShadow={true} /> 
                {/* <Points /> */}
                <Plane /> 
            </Canvas>
            </Suspense>
        </div>

        )
        }
}