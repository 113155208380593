import React from 'react';
import { StepData } from './assets/data';
import {PromoElements} from './Promos';
import {ContactForm } from './ContactForm';
import { Scrollama, Step } from 'react-scrollama';

export class ScrollElements extends React.Component {
    constructor(props) {
        super(props);
        this.handleStepEnter = this.handleStepEnter.bind(this);

    }
    handleStepEnter({data}){
        
        this.props.onStepChange(data);
    }
    resetStep({data}){ 

    }
    render () {
        return (
            <div className="wrapAll " style={{position:'relative', zIndex:100, top:0}} >  
                <div style={{ margin: '0' }}>
                <Scrollama onStepEnter={this.handleStepEnter}>
                 
                    {StepData.map((textData, stepIndex) => (
                       
                    <Step data={textData.step} key={stepIndex}>
  
                            <div
                            className={textData.class}
                            style={{
                                margin: (stepIndex < StepData.length -1) ?  '0 0 50vh 0' : '15px 0 0 0',
                                padding: ' 42px 0',
                            }}
                            >
                            <div className="textInfo">
                                <h2>{textData.intro}</h2>
                                
                                <div dangerouslySetInnerHTML={{__html:textData.text}}></div>
                    
                            </div> 
                 
                                {(textData.links.length > 0) ? 
                                    <PromoElements elements={textData.links} /> 
                                    : null }
                            { (stepIndex === StepData.length -1) ? <ContactForm/> 
                            : null}
                            </div>

                    </Step>
                    ))} 
                </Scrollama>
                </div>
            </div>
        );
    }
};