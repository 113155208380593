import React from 'react';

export class PromoElements extends React.Component {
    constructor(props){
        super(props);
        this.elements = props.elements;
    }
    renderPromoBlock(promo){
        return (
                <div className="promos three" key={promo.link}>
                    <a href={promo.link} target="_blank" rel="noreferrer">
                        <div className="promoImage" style={{
                            backgroundImage:`url(${promo.image})`,
                            backgroundPosition:"50% 50%" }} alt={`${promo.text} article for WSJ.com`} >

                        </div>
                    </a>
                    <h3> <a href={promo.link} target="_blank" rel="noreferrer">{promo.text}</a></h3>

                </div>
        )
    }
    render(){
    const promos  = this.elements;

        return (
            <div className="promoWrap">
                {promos.map(promo=>{
                    return this.renderPromoBlock(promo)
                })}
            </div>
        )
    }
}