import React from 'react';

export function PointLight(props) {
    // This reference gives us direct access to the THREE.Mesh object
   // const ref = useRef()

    // Subscribe this component to the render-loop, rotate the mesh every frame
   // useFrame((state, delta) =>( ref.current.rotation.x += 1.3  ));
    // Return the view, these are regular Threejs elements expressed in JSX
    const d = new Date(); 
    let hour = d.getHours();
    let y = 15;
    return ( //0xFFAA00
        <>
        {/* <spotLight position={[-220,y,1]} color={0xFD5E53} intensity={0.3} penumbra={0.2} castShadow  />  */}
        <spotLight position={[-20,y,1]} color={0xFC9C54} intensity={0.3} penumbra={0.2} castShadow  />
        <spotLight position={[20,y,1]} color={0xFF3E7E} intensity={0.3} penumbra={0.2} castShadow />
        </>
    )
  }