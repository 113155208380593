import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import {App} from './App.jsx';



ReactDOM.render(
  <div className="wrap">
    <App />
  </div>,
  document.getElementById('root')
)


