import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

const pages = [{'name': 'About', 'idx': 1}, {'name': 'Past Work', 'idx': 2}, {'name': 'More', 'idx': 3}, {'name': 'Contact', 'idx': 4}]; //'Blog'

export const ResponsiveAppBar = (props) => {


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState();
  
  React.useEffect(()=>{
  
    setSelectedIndex(props.stepIndex);
  }, [props.stepIndex])

  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (el) => {

    setAnchorElNav(null);
  };
  const handleMenuItemClick = (el, idx ) =>{  
    let curr_idx = idx;
    setSelectedIndex(curr_idx);

    const scroll_to_element = document.querySelector(`[data-react-scrollama-id=react-scrollama-${curr_idx}]`)
    scroll_to_element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  };


  return (
    <AppBar position="static"  style={{background:"rgba(255,255,255, 0.7)"}}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="andjam.es digital development"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} 
                index={page.idx }
                onClick={(event)=> handleMenuItemClick(event, page.idx)}  
                selected={page.idx  === selectedIndex}
                 >  
                  <Typography textAlign="center" >{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            🐵
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              
              <MenuItem
                key={page.name}
                classes={{
                  selected: 'menu-selected'
                }}
                btn-index={page.idx}
                selected={page.idx === selectedIndex}
                onClick={(event)=> handleMenuItemClick(event, page.idx)}
                sx={{ my: 1, color: 'black', display: 'block' }}
              >
                {page.name}
              </MenuItem>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
//export default ResponsiveAppBar;