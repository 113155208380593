import React, {useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useFrame } from '@react-three/fiber';

export const StarFerry = (props) => {
    const ref = useRef();
    const step = props.cameraLocationStep;
    const camera = props.camera;
    const [model, setModel] = useState();
    

    const controls = {

        moveForward: false,
        moveBackward: false,
        moveLeft: false,
        moveRight: false

    };
    
    useEffect(()=>{
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/jsm/libs/draco/');    
        
        const glbLoader = new GLTFLoader();
        glbLoader.setDRACOLoader(dracoLoader);
        
        glbLoader.load('str_frry/starferry-sm.glb', (gltf)=> { 

            gltf.scene.traverse(function(model) {
                
                if (model.isMesh) {
                  model.castShadow = true;
                }
              });
            gltf.scene.name = 'starferry';
            return setModel(gltf.scene);

        });


    },[]);

    useFrame((state, delta) => {
    
        let rotateAngle = new THREE.Vector3(0, 1, 0);
        let walkDirection = new THREE.Vector3(0,15.5, 0);
        const keyControl = (ev) =>{
            //https://www.youtube.com/watch?v=C3s0UHpwlf8 || https://github.com/tamani-coding/threejs-character-controls-example/blob/main/src/characterControls.ts
            const keypressed = ev.key;
            let directionOffsetValue;      
                switch(keypressed) {
                    case 'w':
                        directionOffsetValue = 0;
                    break;
                    case 's':
                        directionOffsetValue = Math.PI;
                    break;
                    case 'a':
                        directionOffsetValue = Math.PI / 2;
                    break;
                    case 'd':
                        directionOffsetValue = -Math.PI / 2;
                    break;
                    default:
                        directionOffsetValue = 0;
    
                    
                }

                const camera = ref.current.parent.getObjectByName('mainCamera');

                const angleYCameraDirection = Math.atan2(
                    (camera.position.x - ref.current.position.x), 
                    (camera.position.z - ref.current.position.z)
                );
 
                //rotate boat:   
 
                let RotateQuaternion = new THREE.Quaternion();
                RotateQuaternion.setFromAxisAngle(rotateAngle, angleYCameraDirection + directionOffsetValue); 

                ref.current.quaternion.rotateTowards(RotateQuaternion, .01);

                //calculate direction:
                camera.getWorldDirection(walkDirection);
                walkDirection.normalize();
                walkDirection.applyAxisAngle(rotateAngle, directionOffsetValue);

                //move model & camera:
                const moveX = walkDirection.x * delta * 2;
                const moveZ = walkDirection.z * delta * 2;

             return (
                ref.current.position.y = 5.5,
                ref.current.position.x += moveX,
                ref.current.position.z += moveZ
                );
        }

        if(ref.current){
            ref.current.castShadow = true;
            ref.current.position.y = 5.5;
            const dir = (step && (step !== 2 || step !== 4)) ? 0.0001 : -0.0001;
            return ( 
                    ref.current.position.z +=  Math.sin(Date.now() * 0.0004) * Math.PI * dir,
                    ref.current.rotation.z += Math.sin(Date.now() * 0.001) * Math.PI * 0.0002 //0.01
                    );
        
        }
        document.addEventListener("keydown", keyControl);

    })
   return (
       model ? <primitive
        ref={ref}
        object={model} 
         /> : null
    )
}