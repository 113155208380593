export const StepData = [{
    'step': 0,
    'intro': '',
    'class': 'holder',
    'text' : ' ',

    'links': []
    },{
    'step': 1,
    'intro': 'ABOUT ME.',
    'class': 'aboutme',
    'text' : `<h3>I'm originally from Canada and I'm a permanent resident of Hong Kong.</h3>
        <h3>I've worked in digital development with a focus on journalism for over 12 years. </h3>
        <h3>I'm experienced with Asian financial market data and working within startup incubators.</h3>
        <h3>In my spare time, I can be found digging into new digital projects, hiking, sailing or walking my dog.</h3>
    `,
    'cameraPosition':[0, 0, 0],
    'links': []
    },
    {
        'step': 2,
        'class': 'work',
        'intro': 'PAST WORK',
        'text' : `<h3>Most recently, I was a multimedia editor and graphics reporter for The Wall Street Journal.</h3>
        <h3>You can find out more on my LinkedIn here <a href='https://www.linkedin.com/in/andrewpjames/'>https://www.linkedin.com/in/andrewpjames</a>.</h3>
        <h3>I worked on such projects as:</h3>
            `,
        'cameraPosition':[15, 65, 15],
        'links': [{
            'image':'https://si.wsj.net/public/resources/images/OG-DW857_0303CM_G_20200304031739.jpg',
            'link':"https://www.wsj.com/graphics/how-the-coronavirus-spread/",
            'text':'How the Coronavirus Spread [SOPA Awards: Excellence in Journalistic Innovation (Honorable Mention)]'
        }, {
            'image':'https://si.wsj.net/public/resources/images/OG-AZ388_NKMISS_D_20171130003624.gif',
            'link':"https://www.wsj.com/graphics/the-threat-from-north-koreas-missiles/",
            'text':'The Threat From North Korea\'s Missiles'
    
        }, {
            'image':'https://images.wsj.net/im-367167/social',
            'link':"https://www.wsj.com/articles/egypt-and-ethiopia-clash-over-nile-river-dam-as-water-scarcity-looms-world-wide-11625925602",
            'text':'Egypt and Ethiopia Clash Over Nile River Dam as Water Scarcity Looms World-Wide'
    
        }]
        
    },{
        'step': 3,
        'class': 'aboutmore',
        'intro': 'MORE ABOUT ME.',
        'text' : `<h3>I'm working on a digital project to make data visualizations more accessible to all. I'll be posting some updates and 
            writing about interesting digital finds here in the near future.
            <br><br>
            As well, I'll be updating this site regularly with new technology I'm looking to test out & play with. <br><br>This current site is a combination of:
                <ul style={textAlign:left}>    
                <li><a href="https://reactjs.org/">React</a> - for the framework</li>
                <li><a href="https://threejs.org/">Three.js</a> - the 3D background</li>
                <li><a href="https://mui.com/" >Material UI</a> - some styling and components</li>
                <li><a href="https://github.com/russellgoldenberg/scrollama" >Scrollama</a> - for the scroll detection, etc.</li>
                </ul>
            </h3>`,
        'cameraPosition':[15, 85, 15],
        'links': []
        
    },{
        'step': 4,
        'class': 'contact',
        'intro': 'GET IN TOUCH!',
        'text' : `<h3>Feel free to <a href="mailto:hello@andjam.es"> email me here:</a> hello@andjam.es for a chat! Contact form coming [very] soon.</h3>`,
        'cameraPosition':[15, 85, 150],
        'links': []
        
    }];