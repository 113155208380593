import './header.css';
import React, {useState, useEffect} from 'react';

const textOpts = [
    ' writer.',
    ' developer.', 
    ' designer.', 
    ' dog parent. 🐶',
    'ndrew.'
  ];

export const Header = ()=>{
    const [TextValue, setTextValue ] = useState('');
    const [opacity, setOpacity ] = useState(0);

useEffect(()=>{

    let textPosition = 0;
    let i = 0;
    let interval_value;
    let textOptLength = textOpts.length;
    const speed = 100;

const TypeIt = ()=>{ 

  
    let text = textOpts[i].substring(0, textPosition + 1);
    setTextValue(text);
    textPosition++;

  
    if(text === textOpts[i]) {
      clearInterval(interval_value);

      if(i !== textOpts.length - 1){
      setTimeout(()=>{
        interval_value = setInterval(DeleteIt, speed);
      }, 1500)
     } else {
        setTimeout(()=>{
            setOpacity(1);
        }, 2000) 
     }
    }
}

const DeleteIt = ()=>{
    let text = textOpts[i].substring(0, textPosition - 1);
    setTextValue(text)
    textPosition--;
  
  if(text === '') {
		clearTimeout(interval_value);
    
    if(i === (textOpts.length - 1)){
      i = 0;
    } else {
      i++;
      textPosition = 0;
      setTimeout(()=>{
        interval_value = setInterval(TypeIt, speed);
      })
    }
    
    
    }
  
}
interval_value = setInterval(TypeIt, speed);
},[]);

//onload:




    return (
        <div className="headerTextWrap">
            <div className="textInfo intro">
                <div className="header">
                    <h3 id="hiIam">Hi, I’m</h3>
                    <h1 id="whoami">
                        a{TextValue}<span>{'\u25ae'}</span>
                    </h1>
                    <p style={{opacity: opacity, textAlign:'left'}}>Welcome to my beta site.</p>
                </div>
            </div>
        </div>
    )
}