import React from 'react';
import {ResponsiveAppBar} from './Menu';
import {Header} from './Header/Header';
import {CanvasElement } from './threeElements/Canvas';
import {ScrollElements} from './ScrollElements';

export class App extends React.Component {
    constructor(props){
        super(props);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.state  = {step: 0};

    }   
    handleStepChange(step){

        this.setState({step: step});
    }
    render(){
        const step = this.state.step;
        return (
            <div>
                <div style={{ position: 'sticky', top: 0, zIndex:200 }}>
                <ResponsiveAppBar stepIndex={step} />
                </div>
                <Header />
                <CanvasElement step={step}  onStepChange={this.handleStepChange} />
                <ScrollElements step={step} onStepChange={this.handleStepChange} />
                {   console.log('%cTry using the WASD keys to control the Star Ferry. 🛶', 'color:#329ea8; font-size:1.5rem; font-family:system-ui; text-shadow:1px 1px 10px #777; font-weight:light; text-decoration:blink; background-color:#000; line-height:50px; margin:5px; padding:5px;')}
            </div>

        );
    }
}   